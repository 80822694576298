import { WistiaPlayer } from '../embeds/wistiaPlayer/WistiaPlayer.tsx';
import { CrossTimeAurora } from './CrossTimeAurora.ts';
import { CrossTimePublicApi } from './CrossTimePublicApi.ts';
import type { PublicApi } from '../types/player-api-types.ts';

type BetweenTimeBinding = {
  callback: () => void;
  endTime: number;
  startTime: number;
  timeUpdateBinding: () => void;
};

abstract class BetweenTimes {
  public bindings: Record<string, BetweenTimeBinding[] | undefined> = {};

  private readonly unbindFunction: () => void;

  public abstract crossTime: CrossTimeAurora | CrossTimePublicApi;

  public abstract embedElement: NonNullable<PublicApi['container']> | WistiaPlayer;

  public constructor(unbindFunction: () => void) {
    this.unbindFunction = unbindFunction;
  }

  public abstract get currentTime(): number;

  public addBinding(startTime: number, endTime: number, callbackFn: () => void): void {
    // since this function is essentially called from the outside via end users, we need to validate the input
    // regardless of typescript argument types
    if (!/^(\d+\.)?\d+$/.test(startTime as unknown as string)) {
      throw new Error('betweenTimes: Expected first argument to be a number');
    }

    if (!/^(\d+\.)?\d+$/.test(endTime as unknown as string)) {
      throw new Error('betweenTimes: Expected second argument to be number');
    }

    if (typeof callbackFn !== 'function') {
      throw new Error('betweenTimes: Expected third argument to be a function');
    }

    const key = this.#getKeyForTimes(startTime, endTime);

    let withinInterval = false;

    const onTimeUpdate = () => {
      const time = this.currentTime;

      if (startTime <= time && time < endTime && !withinInterval) {
        withinInterval = true;
        const result = callbackFn.call(this.unbindFunction, withinInterval) as unknown;

        if (result === this.unbindFunction) {
          this.removeBinding(startTime, endTime, callbackFn);
        }
      } else if (!(startTime <= time && time < endTime) && withinInterval) {
        withinInterval = false;
        const result = callbackFn.call(this.unbindFunction, withinInterval) as unknown;

        if (result === this.unbindFunction) {
          this.removeBinding(startTime, endTime, callbackFn);
        }
      }
    };

    this.crossTime.addBinding(startTime, onTimeUpdate);
    this.crossTime.addBinding(endTime, onTimeUpdate);
    this.embedElement.addEventListener('time-update', onTimeUpdate);

    onTimeUpdate();

    // if an entry doesn't exist for this time, create an array at this "slow"
    // Use an array to store multiple callback bindings at the same time
    if (!this.bindings[key]) {
      this.bindings[key] = [];
    }

    this.bindings[key].push({
      callback: callbackFn,
      endTime,
      startTime,
      timeUpdateBinding: onTimeUpdate,
    });
  }

  public removeAllBindings(): void {
    Object.keys(this.bindings).forEach((betweenTime) => {
      const bindingsAtTime = this.bindings[betweenTime];
      bindingsAtTime?.forEach((binding) => {
        this.embedElement.removeEventListener('time-update', binding.timeUpdateBinding);
        this.crossTime.removeBinding(binding.startTime, binding.timeUpdateBinding);
        this.crossTime.removeBinding(binding.endTime, binding.timeUpdateBinding);
      });
    });
    this.bindings = {};
  }

  public removeBinding(startTime: number, endTime: number, callbackFn: () => void): void {
    const key = this.#getKeyForTimes(startTime, endTime);
    const bindingsBetweenTimes = this.bindings[key];
    const indexesToRemove: number[] = [];

    if (!bindingsBetweenTimes) {
      return;
    }

    bindingsBetweenTimes.forEach((binding, index) => {
      if (binding.callback === callbackFn) {
        indexesToRemove.push(index);
        this.embedElement.removeEventListener('time-update', binding.timeUpdateBinding);
        this.crossTime.removeBinding(startTime, binding.timeUpdateBinding);
        this.crossTime.removeBinding(endTime, binding.timeUpdateBinding);
      }
    });

    // remove the bindings entirely
    indexesToRemove.forEach((index: number) => {
      bindingsBetweenTimes.splice(index, 1);
    });
  }

  #getKeyForTimes(startTime: number, endTime: number): string {
    return `${startTime}-${endTime}`;
  }
}

export { BetweenTimes };
