import { isNil, isNotNil, isNotNumber, isNumber, Nilable } from '@wistia/type-guards';
import merge from 'lodash.merge';
import type { CarouselPlayerEmbedOptions, EndPlaylistBehavior } from '../../../types/carousel.ts';
import { Wistia } from '../../../wistia_namespace.ts';
import { EmbedOptions, MediaData, PlayerTransitionType } from '../../../types/player-api-types.ts';
import { cdnFastWistiaNetHost } from '../../../utilities/hosts.js';
import { getMediaDataFromCache } from '../../../utilities/remote-data-cache.ts';
import { fetchMediaData } from '../../../utilities/fetchMediaData.ts';
import { WistiaPlayer } from '../../wistiaPlayer/WistiaPlayer.tsx';
import { NoNextMediaError } from './NoNextMediaError.ts';
import { NoPreviousMediaError } from './NoPreviousMediaError.ts';
import { isMediaDataError } from '../../../utilities/mediaDataError.ts';

const FAST_HOSTNAME: string = cdnFastWistiaNetHost();

export const PLAYLIST_METHODS_AUTOPLAYED_NEXT_MEDIA_EVENT =
  'playlist-methods-autoplay-next-media-started';

export const PLAYLIST_METHODS_CHANGED_MEDIA_EVENT = 'playlist-methods-changed-media';
export type PLAYLIST_METHODS_CHANGED_MEDIA_EVENT_PAYLOAD = Event & {
  detail: {
    mediaId: string;
  };
};

export type PlaylistMedia = {
  durationInSeconds?: number;
  embedOptions?: EmbedOptions;
  hashedId: string;
  name?: string;
};

type PlaylistMethodsDataSource = {
  id: string;
  type: 'channel' | 'player-api';
};

export type PlaylistOptions = {
  autoAdvance?: boolean;
  endBehavior?: EndPlaylistBehavior;
  isInsidePlayerControl?: boolean;
  overrideEmbedOptions?: CarouselPlayerEmbedOptions;
  source: PlaylistMethodsDataSource;
  startIndex?: number;
  transition?: PlayerTransitionType;
};

export class PlaylistMethods {
  public activeMediaId: string;

  public activeMediaIndex: number;

  public isInitialized: boolean;

  public readonly orderedPlaylistMedias: PlaylistMedia[];

  public readonly sourceIdentifier: string | undefined;

  private _autoAdvance: boolean;

  private readonly _embedHost: string;

  private _endBehavior: EndPlaylistBehavior;

  private readonly _isInsidePlayerControl: boolean;

  private readonly _overrideEmbedOptions: CarouselPlayerEmbedOptions | undefined;

  private readonly _player: WistiaPlayer;

  private readonly _transition: PlayerTransitionType;

  public constructor(
    orderedPlaylistMedias: PlaylistMedia[],
    wistiaPlayer: WistiaPlayer,
    options: PlaylistOptions,
    embedHost: Nilable<string>,
  ) {
    this.sourceIdentifier = `${options.source.type}_${options.source.id}`;
    this._embedHost = embedHost ?? FAST_HOSTNAME;
    this.orderedPlaylistMedias = orderedPlaylistMedias;
    this._player = wistiaPlayer;

    this._autoAdvance = options.autoAdvance ?? true;
    this._endBehavior = options.endBehavior ?? 'default';
    this._transition = options.transition ?? 'fade';
    this._isInsidePlayerControl = options.isInsidePlayerControl ?? false;
    this._overrideEmbedOptions = options.overrideEmbedOptions;

    if (isNumber(options.startIndex) && options.startIndex >= orderedPlaylistMedias.length - 1) {
      this.activeMediaIndex = this._lastMediaIndex;
      this.activeMediaId = orderedPlaylistMedias[this._lastMediaIndex].hashedId;
    } else if (isNotNumber(options.startIndex)) {
      this.activeMediaIndex = 0;
      this.activeMediaId = orderedPlaylistMedias[0].hashedId;
    } else {
      this.activeMediaIndex = options.startIndex;
      this.activeMediaId = orderedPlaylistMedias[options.startIndex].hashedId;
    }
    if (this._autoAdvance) {
      this._player.addEventListener('ended', this._handlePlayerEnded);
    }
    if (isNil(Wistia.playlistMethods)) {
      Wistia.playlistMethods = new Map();
    }
    Wistia.playlistMethods.set(this.sourceIdentifier, this);
  }

  private get _lastMediaIndex(): number {
    return this.orderedPlaylistMedias.length - 1;
  }

  public addMediaToPlaylist(media: PlaylistMedia, options?: { index?: number }): void {
    if (isNotNil(options) && isNotNil(options.index)) {
      this.orderedPlaylistMedias.splice(options.index, 0, media);
    } else {
      this.orderedPlaylistMedias.push(media);
    }
  }

  public getNextMedia(): PlaylistMedia | undefined {
    if (this.activeMediaIndex === this._lastMediaIndex) {
      if (this._endBehavior === 'no-loop') {
        return undefined;
      }
      return this.orderedPlaylistMedias[0];
    }

    return this.orderedPlaylistMedias[this.activeMediaIndex + 1];
  }

  public getPreviousMedia(): PlaylistMedia | undefined {
    if (this.activeMediaIndex === 0) {
      if (this._endBehavior === 'no-loop') {
        return undefined;
      }
      return this.orderedPlaylistMedias[this._lastMediaIndex];
    }

    return this.orderedPlaylistMedias[this.activeMediaIndex - 1];
  }

  public async playMedia(media: PlaylistMedia): Promise<void> {
    this.activeMediaIndex = this._getIndexOfMediaInPlaylist(media.hashedId);
    this.activeMediaId = media.hashedId;
    await this._enqueueMedia(media);
    this.prefetchNextMedia();

    // It's likely we'll move forward through the playlist, so the previous media is already cached
    // But just in case startIndex was > 0 and we move backwards, try to prefetch the previous media for a better UX
    this.prefetchPreviousMedia();
  }

  public async playNextMedia(isTriggeredByPlayerEndedEvent = false): Promise<void> {
    return new Promise((resolve, reject) => {
      const nextMedia = this.getNextMedia();
      if (isNil(nextMedia)) {
        const error = new NoNextMediaError('no next media');
        reject(error);
        throw error;
      }
      this.activeMediaId = nextMedia.hashedId;
      this.activeMediaIndex =
        this.activeMediaIndex === this._lastMediaIndex ? 0 : this.activeMediaIndex + 1;

      void this._enqueueMedia(nextMedia, isTriggeredByPlayerEndedEvent)
        .then(() => {
          this.prefetchNextMedia();
          resolve();
        })
        .catch((reason: unknown) => {
          if (reason instanceof Error) {
            reject(reason);
          }
          reject(new Error(`failed to enqueue media ${nextMedia.hashedId}`));
        });
    });
  }

  public async playPreviousMedia(): Promise<void> {
    return new Promise((resolve, reject) => {
      const previousMedia = this.getPreviousMedia();
      if (isNil(previousMedia)) {
        const error = new NoPreviousMediaError('no previous media');
        reject(error);
        throw error;
      }
      this.activeMediaId = previousMedia.hashedId;
      this.activeMediaIndex =
        this.activeMediaIndex === 0 ? this._lastMediaIndex : this.activeMediaIndex - 1;
      this._enqueueMedia(previousMedia)
        .then(() => {
          // It's likely we're moving forward through the playlist, so the previous media is already cached
          // But just in case startIndex was > 0 and we move backwards, try to prefetch the previous media for a better UX
          this.prefetchPreviousMedia();
          resolve();
        })
        .catch((reason: unknown) => {
          if (reason instanceof Error) {
            reject(reason);
          }
          reject(new Error(`failed to enqueue media ${previousMedia.hashedId}`));
        });
    });
  }

  public prefetchNextMedia(): void {
    const nextMedia = this.getNextMedia();
    if (isNil(nextMedia)) {
      return;
    }
    void this._prefetchMedia(nextMedia);
  }

  public prefetchPreviousMedia(): void {
    const previousMedia = this.getPreviousMedia();
    if (isNil(previousMedia)) {
      return;
    }
    void this._prefetchMedia(previousMedia);
  }

  public removePlayerEndedListener(): void {
    this._player.removeEventListener('ended', this._handlePlayerEnded);
  }

  public setAutoAdvance(value: boolean): void {
    this._autoAdvance = value;
  }

  public setEndBehavior(value: EndPlaylistBehavior): void {
    this._endBehavior = value;
  }

  private async _enqueueMedia(
    media: PlaylistMedia,
    isTriggeredByPlayerEndedEvent = false,
  ): Promise<void> {
    const prefetchedMedia = await this._prefetchMedia(media);
    if (isNil(prefetchedMedia)) {
      return Promise.resolve();
    }

    const isFirstMediaInPlaylist = this.orderedPlaylistMedias[0].hashedId === media.hashedId;

    // When the carousel is rendered inside a player control, it is re-mounted
    // each time replaceWithMedia is called, which in turn re-instantiates this class.
    // So when this is created within a player control, we should remove the 'ended'
    // event listener on the player before the new media is loaded and we lose
    // the reference to the event listener.
    if (this._autoAdvance && this._isInsidePlayerControl) {
      this.removePlayerEndedListener();
    }

    await this._player.replaceWithMedia(prefetchedMedia.hashedId, {
      ...prefetchedMedia.embedOptions,
      ...(this._overrideEmbedOptions ?? {}),
      transition: this._transition,
    });

    if (isTriggeredByPlayerEndedEvent) {
      document.dispatchEvent(new CustomEvent(PLAYLIST_METHODS_AUTOPLAYED_NEXT_MEDIA_EVENT));
    }

    let shouldPlay = false;

    // If we enqueued this media by selecting it or calling playNext/playPrevious on this media, we should always play it.
    if (!isTriggeredByPlayerEndedEvent) {
      shouldPlay = true;
    } else if (this._autoAdvance) {
      // Otherwise if this media was enqueued because the previous media ended and auto advance is true:
      // if we have looped back around to the first media in the playlist, we should only play it if the end behavior is "default"
      // otherwise if this is not the first media in the playlist, we should play it.
      shouldPlay = isFirstMediaInPlaylist ? this._endBehavior === 'default' : true;
    }

    if (shouldPlay) {
      await this._player.play();
    }

    document.dispatchEvent(
      new CustomEvent(PLAYLIST_METHODS_CHANGED_MEDIA_EVENT, {
        detail: {
          mediaId: prefetchedMedia.hashedId,
        },
      }),
    );
    return Promise.resolve();
  }

  private _getCachedMediaDataAsPlaylistMedia(hashedId: string): PlaylistMedia | null {
    const cachedMediaData = getMediaDataFromCache(hashedId);
    if (isNotNil(cachedMediaData)) {
      return {
        durationInSeconds: cachedMediaData.duration ?? 0,
        embedOptions: cachedMediaData.embedOptions ?? {},
        hashedId: cachedMediaData.hashedId ?? '',
        name: cachedMediaData.name ?? '',
      } satisfies PlaylistMedia;
    }
    return null;
  }

  private _getIndexOfMediaInPlaylist(hashedId: string): number {
    return Math.max(
      this.orderedPlaylistMedias.findIndex((media) => media.hashedId === hashedId),
      0,
    );
  }

  private _getMediaDataAsPlaylistMedia(mediaData: MediaData): PlaylistMedia {
    return {
      durationInSeconds: mediaData.duration ?? 0,
      embedOptions: mediaData.embedOptions ?? {},
      hashedId: mediaData.hashedId ?? '',
      name: mediaData.name ?? '',
    } satisfies PlaylistMedia;
  }

  private readonly _handlePlayerEnded = (): void => {
    void this.playNextMedia(true);
  };

  private async _prefetchMedia(media: PlaylistMedia): Promise<PlaylistMedia | null> {
    // If the playlist media has password-protection enabled, we should delete this before merging
    // with the cached/newly cached media data, as the password may have been unlocked/removed,
    // and merging the embed options will re-introduce the password.
    // If a password should be set, it will be introduced by the cached/newly cached media data.
    if (media.embedOptions?.plugin?.passwordProtectedVideo) {
      // eslint-disable-next-line no-param-reassign
      delete media.embedOptions.plugin.passwordProtectedVideo;
    }
    const cachedPlaylistMedia = this._getCachedMediaDataAsPlaylistMedia(media.hashedId);
    if (isNotNil(cachedPlaylistMedia)) {
      merge(cachedPlaylistMedia.embedOptions, media.embedOptions);
      return Promise.resolve(cachedPlaylistMedia);
    }

    const fetchMediaEmbedOptions = {
      ...media.embedOptions,
      embedHost: this._embedHost,
    };

    // Make sure to send along the channel ID if one exists
    if (isNotNil(media.embedOptions?.channelId)) {
      fetchMediaEmbedOptions.channelId = media.embedOptions.channelId;
    }

    // Make sure to send along the channel password if one exists
    if (isNotNil(media.embedOptions?.channelPassword)) {
      fetchMediaEmbedOptions.channelPassword = media.embedOptions.channelPassword;
    }

    const mediaDataOrError = await fetchMediaData(media.hashedId, fetchMediaEmbedOptions);
    if (isMediaDataError(mediaDataOrError)) {
      return Promise.resolve(null);
    }
    const mediaData = mediaDataOrError as MediaData;
    const newlyCachedPlaylistMedia = this._getMediaDataAsPlaylistMedia(mediaData);
    merge(newlyCachedPlaylistMedia.embedOptions, media.embedOptions);
    return newlyCachedPlaylistMedia;
  }
}
