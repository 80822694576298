import { PublicApi } from '../types/player-api-types.ts';
import { CrossTime } from './CrossTime.ts';

class CrossTimePublicApi extends CrossTime {
  public readonly apiInstance: PublicApi;

  public readonly embedElement: NonNullable<PublicApi['container']>;

  public constructor(apiInstance: PublicApi, unbindFunction: () => void) {
    super(unbindFunction);

    this.apiInstance = apiInstance;

    this.embedElement = apiInstance.container as unknown as NonNullable<PublicApi['container']>;
  }

  public get currentTime(): number {
    return this.apiInstance.time();
  }

  public get state(): string {
    return this.apiInstance.state();
  }
}

export { CrossTimePublicApi };
