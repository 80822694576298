import { WistiaPlayer } from '../embeds/wistiaPlayer/WistiaPlayer.tsx';
import { BetweenTimes } from './BetweenTimes.ts';
import { CrossTimeAurora } from './CrossTimeAurora.ts';

class BetweenTimesAurora extends BetweenTimes {
  public readonly apiInstance: WistiaPlayer;

  public readonly crossTime: CrossTimeAurora;

  public readonly embedElement: WistiaPlayer;

  public constructor(apiInstance: WistiaPlayer, unbindFunction: () => void) {
    super(unbindFunction);

    this.apiInstance = apiInstance;
    this.embedElement = apiInstance;

    this.crossTime = new CrossTimeAurora(this.apiInstance, unbindFunction);
  }

  public get currentTime(): number {
    return this.apiInstance.currentTime;
  }
}

export { BetweenTimesAurora };
