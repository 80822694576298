import { Component, h } from 'preact';
import { visuallyHiddenElementStyles } from './visuallyHiddenElementStyles.ts';

class VisuallyHiddenElem extends Component {
  render() {
    const TagName = this.props.tagName || 'div';
    return (
      <TagName {...this.props} ref={this.props.elemRef} style={visuallyHiddenElementStyles}>
        {this.props.children}
      </TagName>
    );
  }
}

export default VisuallyHiddenElem;
