import { getLocalStorage } from 'utilities/namespacedLocalStorage.js';

const LIVE_LOCAL_STORAGE_DATA_KEY = 'wistia:live:data';

export const getLiveLocalStorageUser = (hashedId, role = 'audience') => {
  const data = getLocalStorage(LIVE_LOCAL_STORAGE_DATA_KEY);
  const path = ['events', hashedId, 'user', role].join('.');
  return getValueByPath(data, path);
};

function getValueByPath(obj, path) {
  return path.split('.').reduce((current, key) => current && current[key], obj);
}
