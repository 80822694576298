const WISTIA_PLAYER_INTER = 'WistiaPlayerInter';

export const whenWistiaPlayerInterLoaded = (callback: () => void): (() => void) => {
  const onFontsLoadingDone = (fontFaceSetLoadEvent: FontFaceSetLoadEvent) => {
    if (
      Array.from(fontFaceSetLoadEvent.fontfaces).some(
        (fontface) => fontface.family === WISTIA_PLAYER_INTER,
      )
    ) {
      callback();
    }
  };

  document.fonts.addEventListener('loadingdone', onFontsLoadingDone);

  return () => {
    document.fonts.removeEventListener('loadingdone', onFontsLoadingDone);
  };
};
