export type GradientColor = [string, number]; // [color, percentage]

export type Gradient = {
  colors: GradientColor[];
  on: boolean;
};

// Gradients can get into this state if the hex code for the color is a numeric
// string and it gets casted to a number.
export type GradientMaybeWithColorsAsNumbers = {
  colors: [number | string, number][];
  on: boolean;
};

export const isGradient = (value: unknown): value is Gradient => {
  if (typeof value !== 'object' || value === null) {
    return false;
  }

  const { colors, on } = value as Gradient;

  if (!on) {
    return true;
  }

  if (!Array.isArray(colors) || typeof on !== 'boolean') {
    return false;
  }

  if (colors.length === 0) {
    return false;
  }

  return colors.every((color) => {
    return typeof color[0] === 'string' && typeof color[1] === 'number';
  });
};
