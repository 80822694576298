import type { PublicApi } from '../types/player-api-types.ts';
import { BetweenTimes } from './BetweenTimes.ts';
import { CrossTimePublicApi } from './CrossTimePublicApi.ts';

class BetweenTimesPublicApi extends BetweenTimes {
  public readonly apiInstance: PublicApi;

  public readonly crossTime: CrossTimePublicApi;

  public readonly embedElement: NonNullable<PublicApi['container']>;

  public constructor(apiInstance: PublicApi, unbindFunction: () => void) {
    super(unbindFunction);

    this.apiInstance = apiInstance;

    this.embedElement = apiInstance.container as unknown as NonNullable<PublicApi['container']>;

    this.crossTime = new CrossTimePublicApi(this.apiInstance, unbindFunction);
  }

  public get currentTime(): number {
    return this.apiInstance.time();
  }
}

export { BetweenTimesPublicApi };
