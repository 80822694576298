import { Wistia } from '../../../wistia_namespace.ts';
import { findScriptInDomBySrc } from '../../../utilities/script-utils.js';
import { mediaDataScriptRegExp, mediaDataUrl } from '../../../utilities/media_data.js';
import { eV1Protocol, mediaDataHost } from '../../../utilities/hosts.js';
import { mediaDataTransforms } from '../../../utilities/media-data-transforms.js';
import { cacheMediaData, getMediaDataFromCache } from '../../../utilities/remote-data-cache.ts';
import { isMediaDataError } from '../../../utilities/mediaDataError.ts';
import type {
  MediaData,
  MediaDataServerResponse,
  MediaDataServerErrorResponse,
} from '../../../types/player-api-types.ts';
import { clone } from '../../../utilities/obj.js';
import { fetchMediaData } from '../../../utilities/fetchMediaData.ts';

export interface GetInitialMediaDataOptions {
  channelId?: number;
  channelPassword?: string;
  deferFetchingToCarousel?: boolean;
  embedHost?: string;
  skipCache?: boolean;
}

// This file is designed to efficiently retrieve media data from multiple potential sources.
// The goal is to obtain the media data as quickly as possible.
// Our legacy embed scripts use JSONP and our new scripts use a JS module.
// It includes functions to check for the presence of these JSONP and JavaScript embed scripts in the DOM,
// which can provide the necessary media data.
// If they aren't there or don't load fast enough, we use server data.

const INTERVAL_TIME = 15;
const JS_LOAD_TIMEOUT = 30000; // 30 seconds
const SERVER_FETCH_FALLBACK_TIMEOUT = 15000; // 15 seconds

const doesJsonpExist = (mediaId: string, options: GetInitialMediaDataOptions = {}): boolean => {
  const url = mediaDataUrl(mediaId, options);
  // the mediaDataUrl above 👆 ends with `json` and we're specifically looking for jsonp here
  // also, really old embed codes may have added query params to the end of the url, which we want to exclude
  const matchUrl = url.replace(/\.json(?!p)/, '.jsonp').replace(/&$/, '');

  const isJsonpScriptInDom = findScriptInDomBySrc(matchUrl, {
    ignoreProtocol: true,
    scriptRegex: mediaDataScriptRegExp(mediaId),
  });

  return Boolean(isJsonpScriptInDom);
};

const jsScriptUrl = (mediaId: string, options: GetInitialMediaDataOptions = {}): string => {
  const host = mediaDataHost(options) as string;
  return `${eV1Protocol()}//${host}/embed/${mediaId}.js`;
};

const doesJsMediaDataScriptExist = (
  mediaId: string,
  options: GetInitialMediaDataOptions = {},
): boolean => {
  const url = jsScriptUrl(mediaId, options);
  const isJsScriptInDom = findScriptInDomBySrc(url, {
    ignoreProtocol: true,
  });

  return Boolean(isJsScriptInDom);
};

const pollForJsonp = async (
  mediaId: string,
  options: GetInitialMediaDataOptions,
): Promise<MediaData | MediaDataServerErrorResponse> => {
  return new Promise((resolve, reject) => {
    let poll = -1;

    // try looking once before the interval. This is especially useful for tests
    // since using jest.useFakeTimers() doesn't work with this function
    const checkForJsonp = () => {
      const jsonp = window[`wistiajsonp-/embed/medias/${mediaId}.jsonp`] as
        | MediaDataServerResponse
        | undefined;

      if (jsonp) {
        if (jsonp.media) {
          clearInterval(poll);

          // Any data from speed demon scripts we know won't be localized, so we can
          // cache it.
          cacheMediaData(mediaId, jsonp.media);

          // Still call fetchMediaData in case we need to get the localized version.
          fetchMediaData(mediaId, options)
            .then(resolve)
            .catch(() => {
              reject(new Error('Failed to fetch media data via jsonp'));
            });
          return;
        }

        if (isMediaDataError(jsonp)) {
          clearInterval(poll);
          resolve(jsonp);
        }
      }
    };

    checkForJsonp();

    poll = window.setInterval(checkForJsonp, INTERVAL_TIME);

    setTimeout(() => {
      clearInterval(poll);
      reject(new Error('Timeout loading jsonp media data'));
    }, JS_LOAD_TIMEOUT);
  });
};

const getJsScript = async (
  mediaId: string,
  options: GetInitialMediaDataOptions = {},
): Promise<MediaData | MediaDataServerErrorResponse> => {
  const url = jsScriptUrl(mediaId, options);
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      reject(new Error('Timeout loading js media data'));
    }, JS_LOAD_TIMEOUT);
    void import(/* webpackIgnore: true */ url)
      .then((module: { mediaData: MediaDataServerResponse }) => {
        const { mediaData } = module;

        // Any data from speed demon scripts we know won't be localized, so we can
        // cache it.
        cacheMediaData(mediaId, mediaData as MediaData);

        fetchMediaData(mediaId, options)
          .then(resolve)
          .catch(() => {
            reject(new Error('Failed to fetch media data via js script'));
          });
      })
      .catch(() => {
        reject(new Error('Failed to load js media data'));
      });
  });
};

const fetchMediaDataDelayed = (
  mediaId: string,
  options: GetInitialMediaDataOptions = {},
): [Promise<MediaData | MediaDataServerErrorResponse>, { current: number }] => {
  const timeoutRef = { current: -1 };
  const promise = new Promise<MediaData | MediaDataServerErrorResponse>((resolve, reject) => {
    timeoutRef.current = window.setTimeout(() => {
      void fetchMediaData(mediaId, options)
        .then(resolve)
        .catch((err: unknown) => {
          reject(err instanceof Error ? err : new Error(String(err)));
        });
    }, SERVER_FETCH_FALLBACK_TIMEOUT);
  });
  return [promise, timeoutRef];
};

export const getInitialMediaData = async (
  mediaId: string,
  options: GetInitialMediaDataOptions = {},
): Promise<MediaData | MediaDataServerErrorResponse> => {
  // If the media data is already in the cache, no need to wait for everything
  // else.  Also, if we've cached from an external source like password
  // protected video, this lets us respect that value.
  const mediaDataFromCache = getMediaDataFromCache(mediaId);
  if (mediaDataFromCache != null && options.skipCache !== true) {
    return fetchMediaData(mediaId, options);
  }

  const inlineMediaData = Wistia._inlineMediaData?.[mediaId];
  if (inlineMediaData != null) {
    // Any data from inline media data we know won't be localized, so we can
    // cache it.
    const clonedInlineMediaData = clone(inlineMediaData) as MediaData;
    mediaDataTransforms(clonedInlineMediaData, options);
    cacheMediaData(mediaId, clonedInlineMediaData);
    // Still call fetchMediaData in case we need to get the localized version.
    return fetchMediaData(mediaId, options);
  }

  const speedDemonPromises: Promise<MediaData | MediaDataServerErrorResponse>[] = [];

  if (doesJsonpExist(mediaId, options)) {
    speedDemonPromises.push(pollForJsonp(mediaId, options));
  }

  if (doesJsMediaDataScriptExist(mediaId, options)) {
    speedDemonPromises.push(getJsScript(mediaId, options));
  }

  // eslint-disable-next-line @typescript-eslint/init-declarations
  let mediaData: MediaData | MediaDataServerErrorResponse | undefined;

  if (speedDemonPromises.length === 0) {
    // If we get the json from the server, it'll do its own caching and mediaDataTransforms,
    // so we don't want to do that here. Doing so here could assign the mediaData to the
    // wrong mediaId (if it's localized) in the cache or double transform the data.
    mediaData = await fetchMediaData(mediaId, options);
  } else {
    const [delayedMediaDataPromise, delayedFetchTimeoutRef] = fetchMediaDataDelayed(
      mediaId,
      options,
    );
    mediaData = await Promise.any([...speedDemonPromises, delayedMediaDataPromise]);
    clearTimeout(delayedFetchTimeoutRef.current);
  }

  return mediaData;
};
