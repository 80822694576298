import { WistiaPlayer } from '../embeds/wistiaPlayer/WistiaPlayer.tsx';
import { CrossTime } from './CrossTime.ts';

class CrossTimeAurora extends CrossTime {
  public readonly apiInstance: WistiaPlayer;

  public readonly embedElement: WistiaPlayer;

  public constructor(apiInstance: WistiaPlayer, unbindFunction: () => void) {
    super(unbindFunction);

    this.apiInstance = apiInstance;
    this.embedElement = apiInstance;
  }

  public get currentTime(): number {
    return this.apiInstance.currentTime;
  }

  public get state(): string {
    return this.apiInstance.state;
  }
}

export { CrossTimeAurora };
