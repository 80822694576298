import { DEFAULT_CAPTION_SETTINGS } from './consts.ts';
import { CaptionSettings, isCaptionsSettings } from './types.ts';
import {
  getViewerPreference,
  setViewerPreference,
} from '../../../../../../../utilities/viewerPreferences.js';

const VIEWER_PREFERENCES_KEY = 'captionsViewerPreferences';

type ViewerCaptionPreferences = Partial<CaptionSettings> & {
  language?: string;
  onByViewerPreferences?: boolean;
};

const isViewerCaptionPreferences = (value: unknown): value is ViewerCaptionPreferences => {
  return typeof value === 'object' && value != null;
};

export const getCaptionViewerPreferences = (): ViewerCaptionPreferences => {
  const captionsViewerPreferences: unknown = getViewerPreference(VIEWER_PREFERENCES_KEY);

  if (!isViewerCaptionPreferences(captionsViewerPreferences)) {
    return {};
  }

  return captionsViewerPreferences;
};

export const getCaptionSettings = (): CaptionSettings => {
  const captionsSettings = getCaptionViewerPreferences();

  if (!isCaptionsSettings(captionsSettings)) {
    return { ...DEFAULT_CAPTION_SETTINGS };
  }

  return captionsSettings;
};

export const updateCaptionViewerPreferences = (
  updates: Partial<ViewerCaptionPreferences>,
): void => {
  setViewerPreference(VIEWER_PREFERENCES_KEY, {
    ...getCaptionViewerPreferences(),
    ...updates,
  });
};
