import { BASE_COLORS, FONT_SIZES, OPACITY_PERCENTAGES } from './consts.ts';

export type FontOptionLabel =
  | 'Casual'
  | 'Cursive'
  | 'Monospace sans-serif'
  | 'Monospace serif'
  | 'Proportional sans-serif'
  | 'Proportional serif'
  | 'Small caps';

export type CharacterEdgeStyleLabel = 'Depressed' | 'Drop shadow' | 'None' | 'Outline' | 'Raised';

export type CaptionFontSizes = 100 | 120 | 140 | 160;

export type CaptionOpacityPercentages = 0 | 25 | 50 | 75 | 100;

export type CaptionColors =
  | '#000000'
  | '#00EAFF'
  | '#00F024'
  | '#001AFF'
  | '#FF0000'
  | '#FF00AA'
  | '#FFF200'
  | '#FFFFFF';

export type CaptionSettings = {
  backgroundColor: CaptionColors;
  backgroundOpacityPercentage: CaptionOpacityPercentages;
  characterEdgeStyle: CharacterEdgeStyleLabel;
  fontColor: CaptionColors;
  fontFamily: FontOptionLabel;
  fontOpacityPercentage: CaptionOpacityPercentages;
  fontSize: CaptionFontSizes;
  windowColor: CaptionColors;
  windowOpacityPercentage: CaptionOpacityPercentages;
};

export const isCaptionFontSize = (value: number): value is CaptionFontSizes =>
  FONT_SIZES.some((fontSize) => fontSize === value);

export const isCaptionOpacityPercentage = (value: number): value is CaptionOpacityPercentages =>
  OPACITY_PERCENTAGES.some((opacityPercentage) => opacityPercentage === value);

export const isCaptionColor = (value: string): value is CaptionColors =>
  BASE_COLORS.some((color) => color === value);

export const isCaptionsSettings = (value: unknown): value is CaptionSettings => {
  if (typeof value !== 'object' || value === null) {
    return false;
  }

  const captionSettings = value as CaptionSettings;

  return (
    isCaptionColor(captionSettings.backgroundColor) &&
    isCaptionOpacityPercentage(captionSettings.backgroundOpacityPercentage) &&
    isCaptionColor(captionSettings.fontColor) &&
    isCaptionFontSize(captionSettings.fontSize) &&
    isCaptionOpacityPercentage(captionSettings.fontOpacityPercentage) &&
    isCaptionColor(captionSettings.windowColor) &&
    isCaptionOpacityPercentage(captionSettings.windowOpacityPercentage)
  );
};
