import type { CSSProperties } from 'preact/compat';

export const visuallyHiddenElementStyles: CSSProperties = {
  clip: 'rect(1px, 1px, 1px, 1px)',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  width: '1px',
};
