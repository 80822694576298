const getAlpha2Code = (iso6392Code: string) => {
  return iso6392Code.split('-')[0];
};

/**
 * Finds the index of the preferred language from a list of available languages.
 *
 * This function helps select the best matching language based on user preferences and browser settings.
 * It's designed to work with arrays of ISO 639-2 language codes, optionally with region codes (e.g. 'en' or 'en-US'),
 * but can also be used for matching alpha3 ietf language tags, e.g. "eng".
 *
 * @param availableLanguages - Array of language codes that are available to choose from
 * @param preferredLanguages - Array of language codes to try matching
 * @returns The index of the best matching language in the availableLanguages array, or -1 if no languages are available
 *
 * @example
 * const captions = [
 *   { language: 'en', text: 'Hello' },
 *   { language: 'es', text: 'Hola' }
 * ];
 * const index = getPreferredAvailableLanguageIndex(
 *   captions.map(c => c.language),
 *   ['es']
 * );
 * const preferredCaption = captions[index];
 */
export const getPreferredAvailableLanguageIndex = (
  availableLanguages: string[],
  preferredLanguages: string[],
): number => {
  // Try exact matches first
  const exactMatch = preferredLanguages.find((preferred) => availableLanguages.includes(preferred));
  if (exactMatch != null) {
    return availableLanguages.indexOf(exactMatch);
  }

  // Try region matches
  const regionMatch = preferredLanguages.find((preferred) => {
    const preferredAlpha2 = getAlpha2Code(preferred);
    return availableLanguages.some((lang) => getAlpha2Code(lang) === preferredAlpha2);
  });

  if (regionMatch != null) {
    return availableLanguages.findIndex(
      (lang) => getAlpha2Code(lang) === getAlpha2Code(regionMatch),
    );
  }

  return -1;
};
