import { interFontFamily } from '../../../../../../../utilities/interFontFamily.js';
import {
  CaptionColors,
  CaptionFontSizes,
  CaptionOpacityPercentages,
  CaptionSettings,
  FontOptionLabel,
} from './types.ts';

export const CAPTIONS_FONT_FAMILY_MAP = new Map<FontOptionLabel, string>([
  ['Monospace serif', `"Courier New", Courier, "Nimbus Mono L", "Cutive Mono", monospace`],
  [
    'Monospace sans-serif',
    `"Deja Vu Sans Mono", "Lucida Console", Monaco, Consolas, "PT Mono", monospace`,
  ],
  ['Proportional serif', `"Times New Roman", Times, Georgia, Cambria, "PT Serif Caption", serif`],
  ['Proportional sans-serif', interFontFamily],
  ['Casual', `"Comic Sans MS", Impact, Handlee, fantasy`],
  ['Cursive', `"Monotype Corsiva", "URW Chancery L", "Apple Chancery", "Dancing Script", cursive`],
  ['Small caps', interFontFamily],
]);

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const FONT_SIZES: CaptionFontSizes[] = [100, 120, 140, 160];

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const OPACITY_PERCENTAGES: CaptionOpacityPercentages[] = [100, 75, 50, 25, 0];

export const NON_ZERO_OPACITY_PERCENTAGES: CaptionOpacityPercentages[] = OPACITY_PERCENTAGES.filter(
  (opacity) => opacity !== 0,
);

export const BASE_COLORS: CaptionColors[] = [
  '#000000',
  '#FFF200',
  '#00F024',
  '#00EAFF',
  '#001AFF',
  '#FF00AA',
  '#FF0000',
  '#FFFFFF',
];

export const DEFAULT_CAPTION_SETTINGS: CaptionSettings = {
  fontSize: FONT_SIZES[0],
  fontOpacityPercentage: OPACITY_PERCENTAGES[0],
  backgroundOpacityPercentage: OPACITY_PERCENTAGES[1],
  windowOpacityPercentage: OPACITY_PERCENTAGES[OPACITY_PERCENTAGES.length - 1],
  fontColor: BASE_COLORS[BASE_COLORS.length - 1],
  backgroundColor: BASE_COLORS[0],
  windowColor: BASE_COLORS[0],
  fontFamily: 'Proportional sans-serif',
  characterEdgeStyle: 'None',
};

export const SETTINGS_MENU_PADDING_X_PX = 16;

export const NARROW_WIDTH_THRESHOLD_PX = 340;
