import { isNil, isNonEmptyRecord, isNonEmptyString } from '@wistia/type-guards';
import { isVisitorTrackingEnabled } from './trackingConsentApi.js';
import { Wistia } from '../wistia_namespace.ts';
import { appHostname } from '../appHostname.js';
import { CURRENT_SHA, TAGGED_VERSION } from './hosts.js';

type ProductType =
  | 'carousel'
  | 'channel'
  | 'form'
  | 'globalListener'
  | 'other'
  | 'player'
  | 'playlist'
  | 'transcript';

const getSampleRatesByProductType = (productType: ProductType) => {
  /* eslint-disable @typescript-eslint/no-magic-numbers */
  switch (productType) {
    case 'carousel':
    case 'playlist':
      return 1;
    case 'channel':
    case 'form':
    case 'transcript':
      return 0.25;
    case 'globalListener':
    case 'other':
    case 'player':
    default:
      // Setting a low sample rate here as a precaution against blowing up Sentry with player errors
      // Ideally we can keep increasing this.
      return 0.01;
  }
  /* eslint-enable @typescript-eslint/no-magic-numbers */
};

const IS_DEV_ENV = process.env.NODE_ENV === 'development';
const IS_TEST_ENV = process.env.NODE_ENV === 'test';

const configureSentry = () => {
  if (
    isNil(window.Sentry) ||
    isNil(window.Sentry.BrowserClient) ||
    isNil(window.Sentry.makeFetchTransport) ||
    isNil(window.Sentry.defaultStackParser) ||
    isNil(window.Sentry.Scope)
  ) {
    return;
  }

  /**
   * Since the webpages our code is embedded in might have their own Sentry instances defined,
   * we cannot use Sentry.init();
   *
   * Instead, we can follow the example provided by Sentry (https://docs.sentry.io/platforms/javascript/best-practices/shared-environments/)
   * and create our own client that we use to report errors. This will prevent any Wistia exceptions
   * from being logged in customer Sentry instances, and it should also help prevent our Sentry instance
   * from picking up exceptions in customer code.
   */
  const client = new window.Sentry.BrowserClient({
    dsn: 'https://a3591ba5e949a37083cc6f5a4191e903@o4505518331658240.ingest.us.sentry.io/4505794284290048',
    transport: window.Sentry.makeFetchTransport,
    stackParser: window.Sentry.defaultStackParser,
    integrations: [],
    release: isNonEmptyString(TAGGED_VERSION) ? TAGGED_VERSION : CURRENT_SHA,
  });
  const scope = new window.Sentry.Scope();
  scope.setClient(client);
  scope.setTags({
    pillar: 'publish',
  });

  Wistia._sentryScope = scope;
  client.init(); // initializing has to be done after setting the client on the scope

  Wistia.isSentryInitialized = true;

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  listenForGlobalErrors();
  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  listenForGlobalUnhandledRejections();
};

export const initializeSentry = (): void => {
  if (isVisitorTrackingEnabled() !== true) {
    return;
  }

  if (!window.Sentry || !Wistia.isSentryInitialized) {
    const sentryLoader = document.createElement('script');
    /**
     * Using the Sentry loader script means Sentry will init a second time after
     * our own call to init inside configureSentry(). The second init will include
     * all the default Sentry integrations, which we do not want.
     *
     * Instead of using the loader script, we can load the bundle directly
     * and configure the client when the script loads. This allows us to maintain
     * our own Sentry client without worrying about it colliding with customer instances of Sentry.
     *
     */
    sentryLoader.src = 'https://browser.sentry-cdn.com/9.6.1/bundle.min.js';
    sentryLoader.crossOrigin = 'anonymous';
    sentryLoader.integrity =
      'sha384-kbRmCeIl7Uxr+vT9YhSAdguCdd4L5QPRj7jzQTanorUVVlw/Y5X9vtzVyOEHLfpH';
    sentryLoader.onload = () => configureSentry();
    document.head.appendChild(sentryLoader);
  }
};

export const reportError = (
  product: ProductType,
  error: Error,
  details?: Record<string, string>,
): void => {
  try {
    if (!Wistia.isSentryInitialized) {
      return;
    }

    const sampleRate = getSampleRatesByProductType(product);

    if (IS_DEV_ENV) {
      console.error(error); // eslint-disable-line no-console
      return;
    }
    if (IS_TEST_ENV) {
      // Do nothing
      return;
    }

    let shouldSendToSentry = false;

    const cryptoObj = isNil(window.crypto) ? window.msCrypto : window.crypto;
    if (cryptoObj !== undefined) {
      const cryptoRandom = cryptoObj.getRandomValues(new Uint32Array(1));
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      shouldSendToSentry = cryptoRandom[0] / (0xffffffff + 1) < sampleRate;
    } else {
      const mathRandom = Math.random();
      shouldSendToSentry = mathRandom < sampleRate;
    }

    if (!shouldSendToSentry) {
      // If we shouldn't send to Sentry, just print to the console
      console.error(error); // eslint-disable-line no-console
      // trackingConsentApi is not yet converted to TS
      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    } else if (isVisitorTrackingEnabled()) {
      // The Sentry docs indicate that we can pass context in a few different ways (https://docs.sentry.io/platforms/javascript/enriching-events/context/#passing-context-directly)
      // Those examples do not actually seem to work.
      // Instead, we will clear out the scope each time we report a new exception and manually re-create it.
      Wistia._sentryScope.clear();
      Wistia._sentryScope.setTag('pillar', 'publish');
      Wistia._sentryScope.setTag('product', product);
      if (isNonEmptyRecord(details)) {
        Wistia._sentryScope.setTags(details);
      }
      Wistia._sentryScope.captureException(error);
    }
  } catch (err) {
    // We don't want any problems with our error logging to break other things,
    // so we'll just print this to the console.
    console.error(err); // eslint-disable-line no-console
  }
};

const globalErrorHandler = (event: ErrorEvent) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const eventError = event.error;
  if (!(eventError instanceof Error)) {
    return;
  }
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const eventErrorSource: string = event.error?.source ?? '';
  const fastHostName = appHostname('fast');
  // Filter out any global errors that didn't originate from Wistia code
  if (eventErrorSource.includes(fastHostName)) {
    reportError('globalListener', eventError);
  }
};

const globalUnhandledRejectionHandler = (event: PromiseRejectionEvent) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const eventReason = event.reason;
  if (!(eventReason instanceof Error)) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  const eventReasonStack: string = event.reason?.stack ?? '';
  // Filter out any unhandled rejections that didn't originate from Wistia code
  if (eventReasonStack.includes(appHostname('fast'))) {
    reportError('globalListener', eventReason);
  }
};

export const listenForGlobalErrors = (): void => {
  if (!Wistia._isListeningForGlobalErrors) {
    window.addEventListener('error', globalErrorHandler);
    Wistia._isListeningForGlobalErrors = true;
  }
};

export const listenForGlobalUnhandledRejections = (): void => {
  if (!Wistia._isListeningForGlobalUnhandledRejections) {
    window.addEventListener('unhandledrejection', globalUnhandledRejectionHandler);
    Wistia._isListeningForGlobalUnhandledRejections = true;
  }
};
