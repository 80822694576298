import { Wistia } from 'wistia_namespace.ts';
import { root } from 'utilities/root.js';
import { merge } from 'utilities/obj.js';
import { removeScriptsBySrc } from 'utilities/script-utils.js';
import {
  cacheMediaData,
  uncacheMediaData,
  getMediaDataFromCache,
} from 'utilities/remote-data-cache.ts';
import {
  eV1Protocol,
  cdnFastWistiaComHost,
  cdnFastWistiaNetHost,
  mediaDataHost,
} from 'utilities/hosts.js';
import { mediaDataTransforms } from 'utilities/media-data-transforms.js';

export const transformResponse = (mediaData, options) => {
  const mediaWithOpts = { ...mediaData };
  const transformOpts = merge({}, mediaWithOpts.media?.embedOptions, options);

  if (mediaWithOpts.error) {
    return mediaWithOpts;
  }

  delete mediaWithOpts.media.unnamed_assets;
  mediaDataTransforms(mediaWithOpts.media, transformOpts);
  return mediaWithOpts.media;
};

export const mediaDataUrl = (hashedId, options = {}) => {
  const host = mediaDataHost(options);
  return `${eV1Protocol()}//${host}/embed/medias/${hashedId}.json`;
};

export const mediaDataScriptRegExp = (hashedId) => {
  const protocolMatch = location.protocol === 'https:' ? 'https' : 'https?';
  return new RegExp(
    `^(${protocolMatch}:)?//((${cdnFastWistiaComHost().replace(
      '.',
      '\\.',
    )})|(${cdnFastWistiaNetHost().replace('.', '\\.')}))/embed/medias/${hashedId}\\.jsonp\\??`,
  );
};

export const cacheMedia = (hashedId, data) => {
  return cacheMediaData(hashedId, data);
};

export const uncacheMedia = (hashedId) => {
  uncacheMediaData(hashedId);
  Wistia.uncacheCaptions?.(hashedId);
  removeSpeedDemonScriptAndData(hashedId);
};

const removeSpeedDemonScriptAndData = (hashedId) => {
  window[`wistiajsonp-/embed/medias/${hashedId}.json`] = null;
  removeScriptsBySrc(mediaDataUrl(hashedId), {
    scriptRegex: mediaDataScriptRegExp(hashedId),
  });
};

export const mediaFromCache = (hashedId) => {
  return dataFromCache(hashedId);
};

const dataFromCache = (hashedId) => {
  const resultFromFetchCache = getMediaDataFromCache(hashedId);
  if (resultFromFetchCache) {
    return resultFromFetchCache;
  }

  const speedDemonData = root[`wistiajsonp-/embed/medias/${hashedId}.json`];
  if (speedDemonData != null && speedDemonData.media) {
    return speedDemonData.media;
  }

  return null;
};
